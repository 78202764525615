<template>
  <el-dialog
      v-model="isShow"
      title="新增设备定位"
      width="33%"
      :before-close="handleClose"
  >
    <el-form ref="formRef" :inline="false" :model="formState" :rules="rules">
      <el-form-item label="所属项目" prop="projectId" label-width="170">
        <el-select v-model="formState.projectId" style="width:80%" filterable clearable
                   placeholder="请选择项目类型" @change="changeProject">
          <el-option
              v-for="project in projectList"
              :key="project.id"
              :label="project.name"
              :value="project.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="设备类型" prop="deviceType" label-width="170">
        <el-select v-model="formState.deviceType" style="width:80%" filterable clearable
                   placeholder="请选择设备类型">
          <el-option
              v-for="dict in dict.type.device_type"
              :key="dict.value"
              :label="dict.label"
              :value="dict.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="设备名称" prop="deviceName" label-width="170">
        <el-input v-model="formState.deviceName" placeholder="请输入设备名称" style="width:80%"/>
      </el-form-item>
      <el-form-item label="安装位置" prop="installationLocation" label-width="170">
        <el-select v-model="formState.installationLocation" style="width:80%" filterable clearable
                   placeholder="请选择安装位置">
          <el-option
              v-for="dict in dict.type.device_location_installation_location"
              :key="dict.value"
              :label="dict.label"
              :value="dict.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="安装方式" prop="installationMethod" label-width="170">
        <el-select v-model="formState.installationMethod" style="width:80%" filterable clearable
                   placeholder="请选择安装方式">
          <el-option
              v-for="dict in dict.type.device_location_installation_method"
              :key="dict.value"
              :label="dict.label"
              :value="dict.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="小桩数量" prop="pileCount" label-width="170" v-if="formState.installationMethod === selectDictValue(dict.type.device_location_installation_method, 'ColumnInstallation')">
        <el-input-number v-model="formState.pileCount" :min="0" :max="99999"  style="width:80%"/>
      </el-form-item>
      <el-form-item label="插座数量" prop="socketCount" label-width="170" v-if="formState.installationMethod === selectDictValue(dict.type.device_location_installation_method, 'WallMount')">
        <el-input-number v-model="formState.socketCount" :min="0"  :max="99999" style="width:80%"/>
      </el-form-item>
      <el-form-item label="所属区域" prop="areaId" label-width="170">
        <el-cascader
            v-model="formState.areaId" :options="areaOptions"
            :props="{value: 'orgId',label: 'orgName',children: 'subOrgList'}"
            style="width:80%"
            @change="setAreaName"
            disabled
        />
      </el-form-item>
      <el-form-item label="详细地址" prop="address" label-width="170">
        <el-input v-model="formState.address" placeholder="请输入详细地址" style="width:80%" disabled/>
      </el-form-item>
      <el-form-item label="整体安装位置图" prop="installationPositionFileList" label-width="170">
        <div>
          <div>
            <span>支持png/jpg，10MB以内</span>
          </div>
          <image-upload
              :value="formState.installationPositionFileList"
              :limit="4" :is-show-tip="false"
              @update:file-list="handleUploadInstallationPositionFile"
              :file-type="fileTypes"
              :file-size="10"
          ></image-upload>
        </div>
      </el-form-item>
      <el-form-item label="点位图" prop="bitmapFileList" label-width="170">
        <div>
          <div>
            <span>支持png/jpg，10MB以内</span>
          </div>
          <image-upload
              :value="formState.bitmapFileList"
              :limit="4" :is-show-tip="false"
              @update:file-list="handleUploadBitmapFile"
              :file-type="fileTypes"
              :file-size="10"
          ></image-upload>
        </div>
      </el-form-item>
      <el-form-item label="取电位置" prop="powerLocation" label-width="170">
        <el-input v-model="formState.powerLocation" placeholder="请输入取电位置" style="width:80%"/>
        <div>
          <span>支持png/jpg，10MB以内</span>
          <image-upload
              :value="formState.powerLocationFileList"
              :limit="4" :is-show-tip="false"
              @update:file-list="handleUploadPowerLocationFile"
              :file-type="fileTypes"
              :file-size="10"
          ></image-upload>
        </div>
      </el-form-item>
      <el-form-item label="线材预算(4mm²线)" prop="wireBudget4" label-width="170">
        <el-input-number v-model="formState.wireBudget4" :precision="2" :step="0.1" :min="0" :max="99999999" style="width:73%"/>
        米
      </el-form-item>
      <el-form-item label="线材预算(1.5mm²线)" prop="wireBudget15" label-width="170">
        <el-input-number v-model="formState.wireBudget15" :precision="2" :step="0.1" :min="0" :max="99999999" style="width:73%"/>
        米
      </el-form-item>
      <el-form-item label="安装费用(元)" prop="fee" label-width="170">
        <el-input-number v-model="formState.fee" :precision="2" :step="0.1" :min="0" :max="99999999" style="width:80%"/>
      </el-form-item>
      <el-form-item label="安装说明" prop="description" label-width="170">
        <el-input style="width:80%" v-model="formState.description" placeholder="请输入安装说明" />
      </el-form-item>
      <el-checkbox label="草稿" size="large" :checked="true" @change="changeCheckBox"/>
    </el-form>
    <template #footer>
          <span class="dialog-footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button type="primary" @click="handleSubmit" :disabled="submitting">
              确定
            </el-button>
          </span>
    </template>
  </el-dialog>
</template>

<script>
import {isResOK, isSysResOK} from '@/api/response'
import {queryOrgRelTree} from "@/api/system";
import {areaOptions} from "@/api/mock";
import {selectDictValue} from "@/utils/ruoyi";
import {getDicts} from "@/api/dict/data";
import ImageUpload from "@/components/ImageUpload/index.vue";
import {addDeviceLocation} from "@/api/device_location";
import {listProject} from "@/api/project";
import {traverse} from "@/utils/common";
import {getSignedProjectOption} from "@/api/project"

export default {
  name: 'NewDeviceLocation',
  dicts: ['device_type', 'device_location_status', 'device_location_installation_location', 'device_location_installation_method'],
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },

  components: {
    ImageUpload,
  },

  emits: ['update:visible', 'completed'],

  data() {
    return {
      formState: {
        projectId: null,
        deviceType: null,
        deviceName: '充电桩',
        areaId: null,
        address: null,
        installationPositionFileList: null,
        bitmapFileList: null,
        powerLocation: null,
        powerLocationFileList: [],
        wireBudget4: null,
        wireBudget15: null,
        fee: null,
        description: null,
        status: '0',
        areaName: null,
        installationLocation: null,
        installationMethod: null,
        pileCount: null,
        socketCount: null,
      },
      fileTypes: ['png', 'jpeg'],
      submitting: false,
      rules: {
        projectId: [
          {required: true, message: "所属项目不能为空", trigger: "blur"}
        ],
        deviceType: [
          {required: true, message: "设备类型不能为空", trigger: "change"}
        ],
        deviceName: [
          {required: true, message: "设备名称不能为空", trigger: "blur"},
          {max: 50, message: '不能超过50个字符', trigger: 'blur'}
        ],
        installationLocation: [
          {required: true, message: "安装位置不能为空", trigger: "blur"},
        ],
        installationMethod: [
          {required: true, message: "安装方式不能为空", trigger: "blur"},
        ],
        pileCount: [
          {required: true, message: "小桩数量不能为空", trigger: "blur"},
        ],
        socketCount: [
          {required: true, message: "插座数量不能为空", trigger: "blur"},
        ],
        areaId: [
          {required: true, message: "所属区域不能为空", trigger: "blur"}
        ],
        address: [
          {required: true, message: "详细地址不能为空", trigger: "blur"}
        ],
        installationPositionFileList: [
          {required: true, message: "安装位置图不能为空", trigger: "blur"}
        ],
        bitmapFileList: [
          {required: true, message: "点位图不能为空", trigger: "blur"}
        ],
        powerLocation: [
          {required: true, validator: this.powerLocationRule(), trigger: "blur"}
        ],
        wireBudget4: [
          {required: true, message: "线材预算不能为空", trigger: "blur"}
        ],
        wireBudget15: [
          {required: true, message: "线材预算不能为空", trigger: "blur"}
        ],
        fee: [
          {required: true, message: "线材预算不能为空", trigger: "blur"}
        ],
      },
      areaOptions: [],
      projectList: [],
    }
  },

  computed: {
    isShow: {
      get() {
        return this.visible
      },

      set(val) {
        this.$emit('update:visible', val)
      },
    },
  },

  created() {
    this.queryOrgRelTree()
    this.fetchDeviceTypeDefaultValue()
    this.fetchProjectList()
    this.setUserInfo()
  },

  methods: {
    selectDictValue,
    setUserInfo(){
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if (userInfo !== undefined){
        this.formState.operatorName = userInfo.name
        this.formState.operatorId = userInfo.id
      }
    },

    setAreaName(){
      this.formState.areaName = undefined
      if (this.formState.areaId != undefined){
        let name = ''
        for (let i= 0; i< this.formState.areaId.length; i++){
          let orgId = this.formState.areaId[i]
          name += traverse(this.areaOptions,orgId)
        }
        this.formState.areaName = name
      }
    },

    powerLocationRule() {
      const locationRule = (rules, value, callback) => {
        if ((this.formState.powerLocation === '' || this.formState.powerLocation === null || !this.formState.powerLocation) &&
            (this.formState.powerLocationFileList && this.formState.powerLocationFileList.length === 0 )) {
          return callback('请上传取电位置')
        }
        return callback()
      }
      return locationRule
    },

    changeProject(val) {
      this.projectList.forEach(project => {
        if (project.id === val) {
          this.formState.areaId = project.areaId
          this.formState.areaName = project.areaName
          this.formState.address = project.address
        }
      })
    },

    fetchProjectList() {
      this.projectList = []
      getSignedProjectOption().then((res) => {
        if (isResOK(res)) {
          this.projectList = res.data
          this.loading = false
        }
      })
    },

    fetchDeviceTypeDefaultValue() {
      getDicts('device_type').then(res => {
        if (isResOK(res)) {
          res.data.forEach(value => {
            if (value.isDefault === 'Y') {
              this.formState.deviceType = value.dictValue
            }
          })
        }
      })
    },

    handleUploadInstallationPositionFile(fileList) {
      this.formState.installationPositionFileList = fileList
    },

    handleUploadBitmapFile(fileList) {
      this.formState.bitmapFileList = fileList
    },

    handleUploadPowerLocationFile(fileList) {
      this.formState.powerLocationFileList = fileList
    },

    changeCheckBox(e) {
      if (e) {
        this.formState.status = selectDictValue(this.dict.type.device_location_status, 'Draft')
      } else {
        this.formState.status = selectDictValue(this.dict.type.device_location_status, 'NotInstalled')
      }
    },

    queryOrgRelTree() {
      queryOrgRelTree({
        "orgId": "1681542405985234945",
        "relType": "1"
      }).then(res => {
        if (isSysResOK(res)) {
          this.areaOptions.push(res.result.orgRelInfoVo)
        }
      })
    },

    handleSubmit() {
      if (this.submitting) {
        this.$message({
          message: '已经提交，无需重复提交',
          type: 'warning',
        })
        return
      }

      this.submitting = true
      this.$refs.formRef.validate((values, fields) => {
        if (values) {
          addDeviceLocation(Object.assign({}, this.formState, { operatingMode: 'web' })).then((res) => {
            if (isResOK(res)) {
              this.$message({
                message: '数据添加成功',
                type: 'success',
              })
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          }).finally(() => {
            this.submitting = false
          })
        } else {
          this.submitting = false
        }
      })
    },
    handleClose() {
      this.isShow = false
    },
  },
}
</script>

<style scoped lang="less">
::v-deep .el-upload--picture-card {
  width: 100px;
  height: 100px;
}

::v-deep .el-upload-list__item {
  width: 100px;
  height: 100px;
}
</style>
